<template>
    <div class="login">
        <el-row type="flex" justify="center" align="middle" class="login-wrapper">
            <el-col :xs="20" :sm="9" class="wrapper" style="min-width: 350px; max-width: 600px; ">
                <div class="top">
                  <!-- <div class="logo-wrap"> 
                      <img v-if="logo" class="logo" :src="logo" />  
                  </div> -->
                  <h1 style="color: #515C69; background: none;margin: 10px;">
                        <!-- {{msg('天海智运')}} -->
                      <span style="font-size:20px; ">{{msg('账户注册')}}</span>
                  </h1>
                </div>
                <div class="wrap">
                    <div class="input-wrap">
                        <el-form :model="user" :rules="regRules" ref="userForm" label-width="80px">
                            <el-form-item :label="msg('登录账号')" prop="username">
                                <el-input :placeholder="msg('只能是英文或者数字')" v-on:keyup.enter.native="focusPwd" prefix-icon="el-icon-s-custom" 
                                    v-model="user.username" style="margin-bottom: 10px;"></el-input>
                            </el-form-item>
                            <el-form-item :label="msg('登录密码')" prop="password">
                                <el-input type="password" :placeholder="msg('请输入密码')" ref="pwdRef" @keyup.enter.native="focusRePwd"  prefix-icon="el-icon-key" 
                                    v-model="user.password" style="margin-bottom:10px;" show-password></el-input>
                            </el-form-item>
                            <el-form-item :label="msg('确认密码')" prop="repassword">
                                <el-input type="password" :placeholder="msg('请确认密码')" ref="repwdRef" @keyup.enter.native="focusCode"  prefix-icon="el-icon-key" 
                                    v-model="user.repassword" style="margin-bottom:10px;" show-password></el-input>
                            </el-form-item>
                            <el-form-item :label="msg('姓名')" prop="fullname">
                                <el-input :placeholder="msg('请输入姓名')" v-on:keyup.enter.native="focusPwd" prefix-icon="el-icon-user" 
                                    v-model="user.fullname" style="margin-bottom: 10px;"></el-input>
                            </el-form-item>
                            <el-form-item :label="msg('手机号码')" prop="tel">
                                <el-input :placeholder="msg('请输入手机号码')" v-on:keyup.enter.native="focusPwd" prefix-icon="el-icon-phone" 
                                    v-model="user.tel" style="margin-bottom: 10px;"></el-input>
                            </el-form-item>
                            <el-form-item :label="msg('验证码')" prop="code">
                                <div style="margin-bottom:20px; display:flex;" >
                                    <el-input :placeholder="msg('验证码')" ref="codeRef" @keyup.enter.native="reg()" prefix-icon="el-icon-picture-outline-round" 
                                        v-model="user.code" style="display: flex;">
                                    </el-input>
                                    <img :src="codeUrl" @click="changeCode()" style="min-width:100px; height:40px;"/>
                                </div>
                            </el-form-item>
                            <el-form-item :label="msg('注册类型')" prop="type">
                                <el-select v-model="user.type" @change="selectRegType">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                                <span style="margin-left:5px;font-size:15px;color: red;">{{"提示：使用 [新会员注册] 类型注册的会员不能对接到任何物流公司！"}}</span>
                            </el-form-item>
                            <el-form-item :label="msg('邀请码')" prop="parentCode">
                                <el-input :placeholder="msg('请输入邀请码')" prefix-icon="el-icon-edit-outline" :disabled="parentCodeDis"
                                    v-model.trim="user.parentCode" style="margin-bottom: 10px;"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="btn-wrap" style="text-align:center;">
                        <el-button type="primary" style="width:150px;" @click="reg()">{{msg('确定注册')}}</el-button> 
                        <el-link style="margin-left:15px;" type="primary" href="login">{{msg('去登录')}}</el-link>
                    </div>
                </div>
            </el-col> 
        </el-row>
        <!-- <div style="width:100%; text-align:center; ">
            <div style="color: white;">{{msg('深圳圣捷国际物流有限公司')}} </div>
        </div> -->
    </div>
</template>

<script>
// @ is an alias to /src
import kit from "../plugins/kit.js";
import { MessageBox } from 'element-ui';
export default {
    name: 'login',
    mounted:function(){
    
        var company = this.$store.state.companyInfo;
        if(company){
            this.companyInfo = this.companyInfo;
        }else{
            this.axios.get(this.$kit.api.companyInfo).then((result) => {
                //console.log(result); 
                if(result.status){
                    this.companyInfo=result.data;
                    this.$store.commit('companyInfo', this.companyInfo);
                } 
            }).catch(function (error) {
                console.error(error);
            });
          
            window.document.title = this.msg("順運寶_好運從這裡開始");
      }
    },
    data(){
         
        var validateTel = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('手机号不能为空'));
            }
            setTimeout(() => {
                let rs = /^1[\d]{10}/
                if(/^[0-9]+$/.test(value)==false){
                    callback(new Error('非法的手机号，手机号为找回密码和修改密码的唯一凭证'));
                }else{
                    if((value+'').indexOf('1') == 0){
                        if(rs.test(value)){
                            callback();
                        }else{
                            callback(new Error('非法的手机号，手机号为找回密码和修改密码的唯一凭证'));
                        }
                    }else{
                        callback();
                    }
                }
            });
        }

        return{
            username: "",
            password: "",
            code:"",
            codeUrl: this.$kit.api.code, 
            imgUrl: this.$kit.api.imgView,
            companyInfo: {},
            user:{
                fullname: null,
                tel:null,
                username: null,
                password: null,
                repassword: null,
                code: null,
                parentCode: null,
                type:1,
            },
            options:[
                {label:'新会员注册',value:0},
                {label:'邀请码注册',value:1},
            ],
            parentCodeDis:false,
            regRules: {
                fullname: [
                    { required: true, message: this.msg("姓名不能为空"), trigger: 'blur' },
                    { min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur' }
                ],
                tel: [
                    {validator: validateTel, trigger: 'blur' },
                ],
                username: [
                    {trigger: 'blur', validator: (rule, value, callback) => {
                            if (!value) {
                                callback(new Error(this.msg("登录账号不能为空")));
                            } else if (/^[a-zA-Z0-9]+$/.test(value)==false) {
                                callback(new Error(this.msg("只能是数字和字母")));
                            } else if(value.length<2 || value.length>30){
                                callback(new Error(this.msg("长度在 2 到 30 个字符")));
                            }else {
                                callback();
                            }
                        }
                    }
                        
                ],
                password: [
                    { required: true, message: this.msg("密码不能为空"), trigger: 'blur' },
                    { min: 6, max: 30, message: '长度在 6 到 50 个字符', trigger: 'blur' }
                ],
                repassword: [
                    {trigger: 'blur', validator: (rule, value, callback) => {
                            if (!value) {
                                callback(new Error(this.msg("请再次输入密码")));
                            } else if (value !== this.user.password) {
                                callback(new Error(this.msg("两次输入密码不一致!")));
                            } else {
                                callback();
                            }
                        }
                    }
                ],
                type: [
                    { required: true, message: this.msg('请选择注册类型'), trigger: 'change' }
                ],
                code: [
                    { required: true, message: this.msg("验证码不能为空"), trigger: 'blur' },
                ],
            }
        }
    }, 

    computed:{
      logo: {
          get(){
            let company = this.$store.state.companyInfo;
            if(company && company['logo']){
              return this.imgUrl+company['logo'];
            }
            return "";
          }
      }, 
    },

    methods:{
        changeCode(){
            this.codeUrl =  this.$kit.api.code+"?"+new Date().getTime();
        },
        focusPwd(){
            this.$refs.pwdRef.focus();
        },
        focusRePwd(){
            this.$refs.repwdRef.focus();
        },
        focusCode(){
            this.$refs.codeRef.focus();
        },
       
        reg(){
            var _this = this;

            this.$refs['userForm'].validate((valid) => {
                if (valid) {
                    this.doReg();
                } else {
                    return false;
                }
            });

        },
        selectRegType(val){
            if(val == 1){
                this.parentCodeDis = false;
            }else{
                this.parentCodeDis = true;
            }
        },
        doReg(){
                let _this = this;
                this.user.tel = this.user.tel+"";
                if(this.user.type == 1){
                    if(!this.user.parentCode){
                        this.$message({ type: 'warning', message: this.msg("请输入邀请码") });
                        return;
                    }
                }
                this.axios.post(kit.api.reg, this.user).then((result) => {
                    //console.log(result); 
                    if(result.status){
                        //存数据，
                        _this.$alert( result.msg || _this.msg("注册失败"), {
                            confirmButtonText: _this.msg('确定'),
                            callback: action => {
                                    _this.$router.push('/login');
                                }
                            });
                    }else{
                        this.$message({ type: 'warning', message: result.msg || "注册失败" });
                        this.changeCode();
                    }
                }).catch(function (error) {
                    console.error(error);
                });
        }
    }

}
</script>

<style scoped lang="scss">

.login{
    height:100%; width:100%; 
    background: url("../assets/img/login-bg.jpg") no-repeat;
    background-size: cover;
    box-sizing: border-box;
}
.login-wrapper{
    position:relative;
    min-height:calc(100% - 85px);
}

.wrapper{
  background: white;
  border-radius: 3px;
  box-sizing: content-box;
  //box-shadow: 0px 0px 10px rgba(28, 88, 150,0.4);  
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;  
}

.top{
//display:flex; align-items: center; 
text-align: center;
}
.logo-wrap{
  width:64px;
  height: 64px;
  overflow:hidden;
}
.logo{
  height:64px;
}

.code-input{
  display:flex;
}

.code-input .el-input__inner{
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}



</style>
